import axios from "axios";
import * as Realm from "realm-web";
import utils from '../utils';
const realmApp = new Realm.App({ id: "eunoe-wcqlq" });
const mongo = realmApp.services.mongodb("mongodb-atlas");

let firstLogin = false;

// Calls a Mongo Realms webhook called sendMagicLink
// to send a magic link to the specified email address.

const sendLink = async (email, create = false) => {
    let sendLinkUrl =
        "https://webhooks.mongodb-realm.com/api/client/v2.0/app/eunoe-wcqlq/service/Eunoe-HTTP/incoming_webhook/sendMagicLink?email=" +
        encodeURIComponent(email) + "&create=" + create;

    console.log(sendLinkUrl);

    if (email == "") {
        return ({ error: "Please enter a valid email address." });
    }

    let response = await fetch(sendLinkUrl)
        .then(response => response.json())
        .then((data) => {
            console.log(data);
            if (data.error) {
                return { error: "Email could not be sent. Please try again." };
            } else {
                return { info: "Link sent. Please check your email." };
            }
        }).catch((error) => {
            console.log(error);
            return { error: "Email could not be sent. Please try again." };
        });

    return response;
}

// Calls a Mongo Realms function called createShareToken
// to create a JWT containing info about the share.

const createShareToken = async (sessionId, questionId) => {

    console.log(sessionId + " " + questionId);

    let url = await realmApp.currentUser.callFunction(
        "userCreateShareToken",
        sessionId, questionId
    );

    return url;

}

const processShareToken = async (token) => {
    let url = "https://webhooks.mongodb-realm.com/api/client/v2.0/app/eunoe-wcqlq/service/Eunoe-HTTP/incoming_webhook/processShareToken?token=" +
        encodeURIComponent(token);

    let response = await fetch(url)
        .then(response => response.json())
        .then((data) => {
            return data;
        }).catch((error) => {
            console.log(error);
            return { error: "Token request failed." };
        });

    return response;
}

const signUp = async (email, password) => {
    if (email == "" || password == "") {
        return { error: "Email or password is blank." };
    }
    let url = "https://webhooks.mongodb-realm.com/api/client/v2.0/app/eunoe-wcqlq/service/Eunoe-HTTP/incoming_webhook/registerUser";
    const response = await axios.post(url, {email, password});
    return response.data;
}

const signIn = async (email, password) => {
    if (email == "" || password == "") {
        return { error: "Email or password is blank." };
    }
    let url = "https://webhooks.mongodb-realm.com/api/client/v2.0/app/eunoe-wcqlq/service/Eunoe-HTTP/incoming_webhook/loginWithPassword";
    let response = await axios.post(url, {email, password});
    if (response.data.error) {
        return response.data;
    }
    response = await login(response.data.token);
    return response;
}

const getResponsesBySessionId = async (sessionId) => {
    let url = "https://webhooks.mongodb-realm.com/api/client/v2.0/app/eunoe-wcqlq/service/Eunoe-VR/incoming_webhook/getResponsesBySessionId";
    let response = await axios.get(url, {params: {sessionId, secret: 'Y6F7VXYWymhdZehQ1F2Tmb8zGU2sLP60'}});
    return response.data;
}

const getSessionsByUserId = async (userId) => {
    let url = "https://webhooks.mongodb-realm.com/api/client/v2.0/app/eunoe-wcqlq/service/Eunoe-VR/incoming_webhook/getSessionsByUserId";
    let response = await axios.get(url, {params: {userId, secret: 'Y6F7VXYWymhdZehQ1F2Tmb8zGU2sLP60'}});
    return response.data;
}

const confirmAccount = async (token, tokenId) => {
    if (token == "" || tokenId == "") {
        return { error: "Token or tokenId are blank." };
    }
    try {
        await realmApp.emailPasswordAuth.confirmUser(token, tokenId);
        return {}
    } catch(err) {
        return { error: utils.getErrorMessage(err) };
    }
}

const resetPassword = async (token, password) => {
    if (token == "" || password == "") {
        return { error: "Token are blank." };
    }
    // try {
    //     await realmApp.emailPasswordAuth.resetPassword(token, password);
    //     return {}
    // } catch(err) {
    //     return { error: utils.getErrorMessage(err) };
    // }
    let url = "https://webhooks.mongodb-realm.com/api/client/v2.0/app/eunoe-wcqlq/service/Eunoe-HTTP/incoming_webhook/resetPassword";
    const response = await axios.post(url, {token, password});
    return response.data;
}

const sendResetPasswordEmail = async (email) => {
    if (email == "") {
        return { error: "Email are blank." };
    }
    let url = "https://webhooks.mongodb-realm.com/api/client/v2.0/app/eunoe-wcqlq/service/Eunoe-HTTP/incoming_webhook/sendResetPasswordEmail";
    const response = await axios.post(url, {email});
    return response.data;
}

// Attempts to authenticate a user based on a magic link token.

const login = async (token) => {
    if (token == "") {
        return { error: "Magic Link blank." };
    }

    const loginPayload = {
        token: token
    };

    const credentials = Realm.Credentials.function(loginPayload);

    const response = realmApp
        .logIn(credentials)
        .then(async (data) => {

            console.log(data);

            let destination = "/dashboard/";

            if (!realmApp.currentUser.customData.realmId) {
                // Force relationship between Realm user and our user.
                await realmApp.currentUser.callFunction("userLinkRealmUserId");

                // Refresh custom data.
                await realmApp.currentUser.refreshCustomData();

                firstLogin = true;
                destination = "/account/edit/";
            }

            return {
                info: "Login successful.",
                location: { path: destination }
            };
        })
        .catch((error) => {
            console.log(error);
            return { error: "The login link was expired or invalid. Use the form below to try again." };
        });

    return response;
}

// Logs out the current realm user.

const logout = async () => {
    await realmApp.currentUser.logOut();
    return;
}

// Refresh custom user data.

const refresh = async () => {
    await realmApp.currentUser.refreshCustomData();
}

// Presigned S3 links.
const createPresignedGetUrl = async (sessionId, filename) => {

    let data = {
        sessionId,
        filename
    };

    let json = JSON.stringify(data);

    let url = await realmApp.currentUser.callFunction(
        "userCreatePresignedGetUrl",
        json
    );

    return url;
}

// Upload file
const uploadFile = async (base64, fileType) => {

    let data = {
        base64,
        fileType
    };

    let json = JSON.stringify(data);

    let url = await realmApp.currentUser.callFunction(
        "uploadFile",
        json
    );

    return url;
}
// Speech to text
const speechToText = async (audioUrl) => {
    let text = await realmApp.currentUser.callFunction(
        "speechToText",
        audioUrl
    );

    return text;
}
// Update function

const insertDocument = async (collection, document) => {
    const mongoCollection = mongo.db("eunoe").collection(collection);
    const result = await mongoCollection.insertOne(document);
    return result;
}

const updateDocument = async (collection, filter, update, options) => {

    const mongoCollection = mongo.db("eunoe").collection(collection);
    const result = await mongoCollection.updateOne(filter, update, options);
    return result;
}

const deleteDocument = async (collection, filter) => {
    const mongoCollection = mongo.db("eunoe").collection(collection);
    const result = await mongoCollection.deleteOne(filter);
    return result;
}

export default {
    Realm: Realm,
    realmApp: realmApp,

    get currentUser() {
        return realmApp.currentUser;
    },
    get authenticated() {
        return (realmApp.currentUser != null);
    },
    get admin() {
        return (realmApp.currentUser != null && realmApp.currentUser.customData.admin == true);
    },
    get firstLogin() {
        return firstLogin;
    },

    // Auth
    login,
    signUp,
    signIn,
    confirmAccount,
    resetPassword,
    sendResetPasswordEmail,
    sendLink,
    logout,
    refresh,

    // S3
    createPresignedGetUrl,

    // Sharing
    createShareToken,
    processShareToken,

    // CRUD
    insert: insertDocument,
    update: updateDocument,
    delete: deleteDocument,
    uploadFile,
    speechToText,
    getResponsesBySessionId,
    getSessionsByUserId
}
