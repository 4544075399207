<template>
  <b-container class="splash" bg-variant="white" fluid>
    <b-row style="min-width: 80vw; min-height: 80%">
      <b-col md="7">
        <b-img
          class="mb-5"
          src="/images/eunoeLogo.svg"
          fluid
          style="max-width: 420px"
        ></b-img>
      </b-col>
      <b-col md="5" class="d-flex align-items-center">
        <b-card v-if="!authenticated">
          <p>Create a new account or sign in by clicking the button below.</p>
          <b-button variant="primary" to="/login/">Sign In</b-button>
        </b-card>

        <b-card v-if="authenticated">
          <p>Click below to access your session dashboard.</p>
          <b-button variant="primary" to="/dashboard/">Dashboard</b-button>
        </b-card>
      </b-col>
    </b-row>
    <div class="splash-image"></div>
  </b-container>
</template>

<script>
import API from "@/api";

export default {
  name: "Home",
  computed: {
    authenticated() {
      return API.authenticated;
    },
  },
};
</script>
