<template>
  <b-container fluid id="app" class="p-0">
    <b-navbar toggleable="sm" type="dark" variant="primary">
      <b-container fluid :class="this.$route.meta.class || 'layout-narrow'">
        <b-navbar-brand to="/" class="mr-auto d-flex align-items-center"
          ><b-img width="32px" class="mr-1" src="/images/eunoeIcon.svg" />
          EUNOE</b-navbar-brand
        >

        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav class="ml-auto">
            <b-nav-item v-if="!authenticated" to="/login/"
              ><b-icon icon="person-circle" aria-hidden="true"></b-icon>
              Login</b-nav-item
            >

            <!-- User links. -->
            <b-nav-item v-if="authenticated" to="/dashboard/"
              ><b-icon icon="bar-chart-line" aria-hidden="true"></b-icon>
              Dashboard</b-nav-item
            >

            <b-nav-item v-if="authenticated" to="/account/"
              ><b-icon icon="person-circle" aria-hidden="true"></b-icon>
              Account</b-nav-item
            >

            <!-- Admin links. -->
            <b-nav-item-dropdown v-if="admin" text="Admin">
              <template slot="button-content">
                <b-icon icon="gear-fill" aria-hidden="true"></b-icon>
                Admin
              </template>
              <b-dropdown-item href="/admin/users">Users</b-dropdown-item>
              <b-dropdown-item href="/admin/programs">Programs</b-dropdown-item>
              <b-dropdown-item href="/admin/modules">Modules</b-dropdown-item>
              <b-dropdown-item href="/admin/questions">Questions</b-dropdown-item>
              <b-dropdown-item href="/admin/options">Options</b-dropdown-item>
              <b-dropdown-item href="/admin/sessions">Sessions</b-dropdown-item>
              <b-dropdown-item href="/admin">Export CSV</b-dropdown-item>
            </b-nav-item-dropdown>
          </b-navbar-nav>
        </b-collapse>
      </b-container>
    </b-navbar>

    <!-- Fullscreen views. -->
    <div :class="this.$route.meta.class || 'layout-narrow'">
      <router-view></router-view>
    </div>

    <footer class="mx-auto mt-2">
      <small class="text-center">
        <p class="text-white">
          &copy;2020 <a href="https://eunoeportal.com">EUNOE</a>
        </p>
      </small>
    </footer>
  </b-container>
</template>

<script>
import API from "@/api";

export default {
  data() {
    return {
      get admin() {
        return API.admin;
      },
      get authenticated() {
        return API.authenticated;
      },
    };
  },
};
</script>

