import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import API from '../api'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { class: "layout-fullscreen" }
  },

  {
    path: '/login',
    name: 'Login',
    props: {
      default: true
    },
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue')
  },
  {
    path: '/login/:token',
    name: 'Login.Token',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue')
  },
  {
    path: '/shared/:token',
    name: 'Shared.Token',
    component: () => import(/* webpackChunkName: "shared" */ '../views/Shared.vue')
  },
  {
    path: '/login-with-password',
    name: 'Login With Password',
    props: {
      default: true
    },
    meta: {
      class: 'login-container',
    },
    component: () => import(/* webpackChunkName: "login" */ '../views/LoginWithPassword.vue')
  },
  {
    path: '/confirm-account',
    name: 'Confirm account',
    props: {
      default: true
    },
    component: () => import(/* webpackChunkName: "login" */ '../views/ConfirmAccount.vue')
  },
  {
    path: '/reset-pass',
    name: 'Reset Pass',
    props: {
      default: true
    },
    component: () => import(/* webpackChunkName: "login" */ '../views/ResetPass.vue')
  },
  {
    path: '/reset-password',
    name: 'Reset Password',
    props: {
      default: true
    },
    component: () => import(/* webpackChunkName: "login" */ '../views/ResetPassword.vue')
  },

  // User Pages

  {
    path: '/account',
    name: 'Account',
    meta: {
      requiresAuth: true
    },
    component: () => import(/* webpackChunkName: "account" */ '../views/Account.vue')
  },
  {
    path: '/account/edit',
    name: 'Account Edit',
    meta: {
      requiresAuth: true
    },
    component: () => import(/* webpackChunkName: "account" */ '../views/AccountEdit.vue')
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    meta: {
      requiresAuth: true
    },
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard.vue')
  },
  {
    path: '/dashboard/session/:id',
    name: 'Session',
    meta: {
      requiresAuth: true
    },
    component: () => import(/* webpackChunkName: "session" */ '../views/Session.vue')
  },

  // Admin pages.

  {
    path: '/admin/',
    name: 'Admin',
    meta: {
      requiresAdmin: true
    },
    component: () => import(/* webpackChunkName: "admin" */ '../views/admin/Admin.vue')
  },
  {
    path: '/admin/user/:id',
    name: 'AdminUser',
    meta: {
      requiresAdmin: true
    },
    component: () => import(/* webpackChunkName: "admin" */ '../views/Dashboard.vue')
  },
  {
    path: '/admin/session/:id',
    name: 'AdminSession',
    meta: {
      requiresAdmin: true
    },
    component: () => import(/* webpackChunkName: "session" */ '../views/Session.vue')
  },
  {
    path: '/admin/users/',
    name: 'AdminUsers',
    meta: {
      requiresAdmin: true
    },
    component: () => import(/* webpackChunkName: "user" */ '../views/admin/AdminUsers.vue')
  },
  {
    path: '/admin/programs/',
    name: 'AdminPrograms',
    meta: {
      requiresAdmin: true
    },
    component: () => import(/* webpackChunkName: "program" */ '../views/admin/AdminPrograms.vue')
  },
  {
    path: '/admin/programs/add',
    name: 'AdminProgramAdd',
    meta: {
      requiresAdmin: true
    },
    component: () => import(/* webpackChunkName: "program" */ '../views/admin/AdminProgramEdit.vue')
  },
  {
    path: '/admin/programs/edit/:id',
    name: 'AdminProgramEdit',
    meta: {
      requiresAdmin: true
    },
    component: () => import(/* webpackChunkName: "program" */ '../views/admin/AdminProgramEdit.vue')
  },
  {
    path: '/admin/modules/',
    name: 'AdminModules',
    meta: {
      requiresAdmin: true
    },
    component: () => import(/* webpackChunkName: "module" */ '../views/admin/AdminModules.vue')
  },
  {
    path: '/admin/modules/add',
    name: 'AdminModuleAdd',
    meta: {
      requiresAdmin: true
    },
    component: () => import(/* webpackChunkName: "module" */ '../views/admin/AdminModuleEdit.vue')
  },
  {
    path: '/admin/:programId/modules/add',
    name: 'AdminModuleAddInProgram',
    meta: {
      requiresAdmin: true
    },
    component: () => import(/* webpackChunkName: "module" */ '../views/admin/AdminModuleEdit.vue')
  },
  {
    path: '/admin/modules/edit/:id',
    name: 'AdminModuleEdit',
    meta: {
      requiresAdmin: true
    },
    component: () => import(/* webpackChunkName: "module" */ '../views/admin/AdminModuleEdit.vue')
  },
  {
    path: '/admin/options/',
    name: 'AdminOptions',
    meta: {
      requiresAdmin: true
    },
    component: () => import(/* webpackChunkName: "question" */ '../views/admin/AdminOptions.vue')
  },
  {
    path: '/admin/options/add',
    name: 'AdminOptionAdd',
    meta: {
      requiresAdmin: true
    },
    component: () => import(/* webpackChunkName: "question" */ '../views/admin/AdminOptionEdit.vue')
  },
  {
    path: '/admin/options/edit/:id',
    name: 'AdminOptionEdit',
    meta: {
      requiresAdmin: true
    },
    component: () => import(/* webpackChunkName: "question" */ '../views/admin/AdminOptionEdit.vue')
  },
  {
    path: '/admin/questions/',
    name: 'AdminQuestions',
    meta: {
      requiresAdmin: true
    },
    component: () => import(/* webpackChunkName: "question" */ '../views/admin/AdminQuestions.vue')
  },
  {
    path: '/admin/questions/add',
    name: 'AdminQuestionAdd',
    meta: {
      requiresAdmin: true
    },
    component: () => import(/* webpackChunkName: "question" */ '../views/admin/AdminQuestionEdit.vue')
  },
  {
    path: '/admin/:moduleId/questions/add',
    name: 'AdminQuestionAddInModule',
    meta: {
      requiresAdmin: true
    },
    component: () => import(/* webpackChunkName: "question" */ '../views/admin/AdminQuestionEdit.vue')
  },
  {
    path: '/admin/questions/edit/:id',
    name: 'AdminQuestionEdit',
    meta: {
      requiresAdmin: true
    },
    component: () => import(/* webpackChunkName: "question" */ '../views/admin/AdminQuestionEdit.vue')
  },
  {
    path: '/admin/sessions/',
    name: 'AdminSessions',
    meta: {
      requiresAdmin: true
    },
    component: () => import(/* webpackChunkName: "session" */ '../views/admin/AdminSessions.vue')
  },
  // 404
  {
    path: '*',
    name: 'NotFound',
    component: () => import(/* webpackChunkName: "notFound" */ '../views/NotFound.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!API.authenticated) {
      console.log("Access denied: " + to.fullPath);
      next('Login');
    } else {
      next();
    }
  } else if (to.matched.some(record => record.meta.requiresAdmin)) {

    if (API.realmApp.currentUser.customData.admin == false) {
      console.log("Access denied: " + to.fullPath);
      next('Home');
    } else {
      next();
    }
  } else {
    next();
  }
})

export default router
