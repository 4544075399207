import Vue from 'vue';
import ApolloClient from 'apollo-client';
import { HttpLink } from 'apollo-link-http';
import { ApolloLink, concat } from 'apollo-link';
import { InMemoryCache } from 'apollo-cache-inmemory';
import VueSocialSharing from 'vue-social-sharing';

import VueApollo from 'vue-apollo'
import App from './App.vue';
import router from './router';
import API from './api';
// Custom css
import './custom.scss'
// BootstrapVue
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';


const httpLink = new HttpLink({ uri: 'https://realm.mongodb.com/api/client/v2.0/app/eunoe-wcqlq/graphql' });

const authMiddleware = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  operation.setContext({
    headers: {
      authorization: API.realmApp.currentUser.accessToken ? `Bearer ${API.realmApp.currentUser.accessToken}` : null,
    }
  });
  return forward(operation);
})

const apolloClient = new ApolloClient({
  link: concat(authMiddleware, httpLink),
  cache: new InMemoryCache()
});

Vue.use(VueApollo)
Vue.use(VueSocialSharing);


const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
  defaultOptions: {
    $loadingKey: 'loading'
  },
  errorHandler (error) {
    console.log('Global error handler')
    console.error(error)
  }
})

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);

// Config

Vue.config.productionTip = false;

new Vue({
  router,
  apolloProvider,
  render: h => h(App)
}).$mount('#app')
